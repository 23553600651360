/* eslint-disable */
import React from "react"
import Footer from "../footer/footer"
import Form from "../contact/form"
import Nav from "../nav/Nav";

const Devis = () => {

    return <div>
        <Nav print/>
        <Form/>
        <Footer/>
    </div>
}

export default Devis
